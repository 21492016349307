import { uuid } from '../../utils/uuid';
export var emptyFixedAsset = function () {
    return {
        id: uuid('_new_record'),
        category: {},
        originalValue: '',
        bookValue: ''
    };
};
export var emptyDebtor = function () {
    return {
        id: uuid('_new_record'),
        name: '',
        amount: ''
    };
};
export var emptyCreditor = function () {
    return {
        id: uuid('_new_record'),
        name: '',
        amount: ''
    };
};
export var emptyExistingLoan = function () {
    return {
        id: uuid('_new_record'),
        name: '',
        amountOutstanding: '',
        monthlyFixedPayment: '',
        noOfMonthsRemaining: ''
    };
};
