var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { CurrencyInput } from '../../components/CurrencyInput';
import FixedAssetCategorySelect from './FixedAssetCategorySelect';
import { currencyToNumber } from '../../../misc/currency';
function FixedAssetRow(props) {
    var _a = React.useState(props.fixedAsset), fixedAsset = _a[0], setFixedAsset = _a[1];
    var _b = React.useState({}), errors = _b[0], setErrors = _b[1];
    var validateFixedAssetValues = function (asset) {
        if (currencyToNumber(asset.originalValue) < currencyToNumber(asset.bookValue)) {
            setErrors(__assign(__assign({}, errors), { originalValue: "Original value must be greater than or equal to the book value." }));
        }
        else {
            setErrors(__assign(__assign({}, errors), { originalValue: undefined }));
        }
    };
    var updateField = function (field) { return function (e) {
        var value = e.target.value;
        updateFieldValue(field)(value);
    }; };
    var updateFieldValue = function (field) { return function (value) {
        var clone = __assign({}, fixedAsset);
        clone[field] = value;
        setFixedAsset(clone);
        props.setParent(field, value);
        validateFixedAssetValues(clone);
    }; };
    return (React.createElement(React.Fragment, null,
        React.createElement("tr", { id: fixedAsset.id },
            React.createElement("td", { className: 'align-middle' },
                React.createElement(FixedAssetCategorySelect, { value: fixedAsset.category, setHook: updateFieldValue('category') })),
            React.createElement("td", null,
                React.createElement(CurrencyInput, { value: fixedAsset.originalValue, setHook: updateFieldValue('originalValue'), className: errors.originalValue && 'is-invalid' })),
            React.createElement("td", null,
                React.createElement(CurrencyInput, { value: fixedAsset.bookValue, setHook: updateFieldValue('bookValue') })),
            React.createElement("td", { className: 'align-middle' },
                React.createElement("a", { className: 'btn btn-sm btn-light border py-2', onClick: props.handleRemoveRow },
                    React.createElement("i", { className: 'fas fa-fw fa-trash' })))),
        errors.originalValue && (React.createElement("tr", { className: "table-borderless" },
            React.createElement("td", null),
            React.createElement("td", { colSpan: 3, className: "pt-0" },
                React.createElement("div", { className: "small text-danger" }, errors.originalValue))))));
}
export default FixedAssetRow;
