$(function() {
  $('[data-field-dependency]').each(function(_index, element) {
    $($(element).data('field-dependency')).change(function() {
      if ($(this).val() === $(element).data('dependency-value').toString()) {
        $(element).show()
      } else {
        $(element).hide()
      }
    })

    $($(element).data('field-dependency')).change()
  })
});
