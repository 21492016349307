$(function() {
  $('[data-password-visibility-toggle]').on('click', function(e) {
    const visibleIcon = 'fa-eye';
    const hiddenIcon = 'fa-eye-slash';
    const visibleFieldType = 'text';
    const hiddenFieldType = 'password';

    const container = $(e.target).closest('[data-password-visibility-container]');

    const icon = container.find('[data-password-visibility-icon]');
    const input = container.find('[data-password-visibility-input]');

    icon.toggleClass([visibleIcon, hiddenIcon]);

    if (input.attr('type') === visibleFieldType) {
      input.attr('type', hiddenFieldType);
    } else {
      input.attr('type', visibleFieldType);
    }
  });
});
