var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { CurrencyInputWithLabel } from '../CurrencyInput';
function AdditionalFieldsCard(props) {
    var _a = React.useState(props.additionalFields), additionalFields = _a[0], setAdditionalFields = _a[1];
    var updateField = function (field) { return function (value) {
        var clone = __assign({}, additionalFields);
        clone[field] = value;
        setAdditionalFields(clone);
        props.setParent(clone);
    }; };
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'card mb-4 font-weight-bold' },
            React.createElement("div", { className: 'card-body' },
                React.createElement(CurrencyInputWithLabel, { label: 'Net Assets', value: additionalFields.netAssets, setHook: updateField('netAssets'), disabled: true }))),
        React.createElement("div", { className: 'card mb-4 font-weight-bold' },
            React.createElement("div", { className: 'card-body' },
                React.createElement(CurrencyInputWithLabel, { label: 'Share Capital', value: additionalFields.shareCapital, setHook: updateField('shareCapital') }),
                React.createElement(CurrencyInputWithLabel, { label: 'Reserves', value: additionalFields.reserves, setHook: updateField('reserves') }))));
}
export default AdditionalFieldsCard;
