import $ from 'jquery';

$(function() {
  const toggleElement = $('#show-suspended-users');

  if (toggleElement.length < 1) return;

  toggleElement.prop('checked', false);

  toggleElement.on('change', function(e) {
    const toggledOn = toggleElement.prop('checked');
    const suspendedUserRows = $('[data-suspended="true"]');
    if (toggledOn) {
      suspendedUserRows.show();
    } else {
      suspendedUserRows.hide();
    }
  })
});
