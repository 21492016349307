// Return array of the months between two dates.
export var monthRange = function (startMonth, endMonth) {
    if (!startMonth.isValid() || !endMonth.isValid()) {
        return [];
    }
    var month = startMonth.clone();
    var output = [];
    while (endMonth > month || month.format('M') === endMonth.format('M')) {
        output.push(month.clone());
        month.add(1, 'month');
    }
    return output;
};
