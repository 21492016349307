var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import * as React from 'react';
import DebtorRow from './DebtorRow';
import { emptyDebtor } from '../../modules/opening-balance-sheet/types';
function DebtorsTable(_a) {
    var debtors = _a.debtors, setParent = _a.setParent;
    var _b = React.useState(debtors), propDebtors = _b[0], setDebtors = _b[1];
    var addDebtor = function () {
        var newDebtors = propDebtors.concat(emptyDebtor());
        setDebtors(newDebtors);
        setParent(newDebtors);
    };
    var removeDebtor = function (id) { return function () {
        updateDebtors(id)('_destroy', true);
    }; };
    var updateDebtors = function (id) { return function (field, value) {
        var clone = __spreadArrays(propDebtors);
        for (var i in clone) {
            if (clone[i].id == id) {
                clone[i][field] = value;
                break;
            }
        }
        setDebtors(clone);
        setParent(clone);
    }; };
    var activeDebtors = function () { return propDebtors.filter(function (debtor) { return !debtor._destroy; }); };
    return React.createElement(React.Fragment, null,
        React.createElement("table", { className: 'table table-borderless table-sm' },
            React.createElement("tbody", null, activeDebtors().map(function (debtor) {
                return React.createElement(DebtorRow, { key: debtor.id, debtor: debtor, handleRemoveRow: removeDebtor(debtor.id), setParent: updateDebtors(debtor.id) });
            })),
            React.createElement("tfoot", null,
                React.createElement("tr", null,
                    React.createElement("td", { className: 'w32p text-right' },
                        React.createElement("a", { className: 'btn btn-light border', onClick: addDebtor },
                            React.createElement("i", { className: 'fas fa-plus mr-2' }),
                            "Add Debtor")),
                    React.createElement("td", { className: 'w21p' }),
                    React.createElement("td", null)))));
}
export default DebtorsTable;
