$(function() {
  $('#create-product-form').each(function() {
    const toggleReadOnlyFields = function() {
      if ($('#set-is-service').is(':checked')) {
        $('#set-margin').val('100%').attr('readonly', true);
        $('#set-days-to-pay').val(0).attr('readonly', true);
        $('#set-vat').val("0.0").attr('readonly', true);
      } else {
        $('#set-margin').attr('readonly', false);
        $('#set-days-to-pay').attr('readonly', false);
        $('#set-vat').attr('readonly', false);
      }
    }

    $('#set-is-service').change(toggleReadOnlyFields);
    toggleReadOnlyFields();
  });
});