var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from "react";
import moment from "moment";
import * as TaxAssumptionsApi from "../modules/tax-assumptions/api";
import * as DataTablesApi from "../modules/datatables/api";
import Cell from "../components/data-table/Cell";
import { monthRange } from "../utils/dates";
import TaxAssumptionsHeaderRow from "../components/data-table/TaxAssumptionsHeaderRow";
import { TimePeriodTotals } from "../components/DataTable";
function DataTable(_a) {
    var cellHint = _a.cellHint, columnsHeader = _a.columnsHeader, tableHeader = _a.tableHeader, noRowsToDisplay = _a.noRowsToDisplay, noMonthsToDisplay = _a.noMonthsToDisplay, initializeTable = _a.initializeTable, commitCellUpdate = _a.commitCellUpdate, onCellUpdate = _a.onCellUpdate, onCellCopyForward = _a.onCellCopyForward, timePeriodTotals = _a.timePeriodTotals, commitCellThresholdUpdate = _a.commitCellThresholdUpdate, onCellCopyForwardThreshold = _a.onCellCopyForwardThreshold;
    var _b = React.useState({}), collection = _b[0], setCollection = _b[1];
    var _c = React.useState(moment('2021-01-01')), startMonth = _c[0], setStartMonth = _c[1];
    var _d = React.useState(moment('2021-12-01')), endMonth = _d[0], setEndMonth = _d[1];
    var _e = React.useState(false), isLoading = _e[0], setIsLoading = _e[1];
    var _f = React.useState({}), monthTotals = _f[0], setMonthTotals = _f[1];
    var _g = React.useState({}), yearTotals = _g[0], setYearTotals = _g[1];
    var monthsForYear = monthRange(startMonth, endMonth);
    React.useEffect(function () {
        setIsLoading(true);
        initializeTable().then(function (_a) {
            var start_month = _a.start_month, end_month = _a.end_month, collection = _a.collection, month_totals = _a.month_totals, year_totals = _a.year_totals;
            setMonthTotals(month_totals);
            setYearTotals(year_totals);
            setCollection(collection);
            setStartMonth(moment(start_month));
            setEndMonth(moment(end_month));
            setIsLoading(false);
        });
    }, []);
    // handlers
    var handleCellValueUpdate = function (rowId, cellId) { return function (newValue) {
        var collectionCopy = __assign({}, collection);
        collectionCopy[rowId].cells[cellId].value = newValue;
        setCollection(collectionCopy);
        if (onCellUpdate)
            onCellUpdate(newValue, rowId, cellId);
    }; };
    var onCellValueBlur = function (rowId, cellId) { return function () {
        setIsLoading(true);
        commitCellUpdate(collection[rowId].cells[cellId].value, rowId, cellId)
            .then(initializeTable)
            .then(function (_a) {
            var month_totals = _a.month_totals, year_totals = _a.year_totals;
            setYearTotals(year_totals);
            setMonthTotals(month_totals);
            setIsLoading(false);
        });
    }; };
    var handleCellThresholdUpdate = function (rowId, cellId) { return function (newThreshold) {
        var collectionCopy = __assign({}, collection);
        collectionCopy[rowId].cells[cellId].threshold = newThreshold;
        setCollection(collectionCopy);
        if (onCellUpdate)
            onCellUpdate(newThreshold, rowId, cellId);
    }; };
    var onCellThresholdBlur = function (rowId, cellId) { return function () {
        setIsLoading(true);
        commitCellThresholdUpdate(collection[rowId].cells[cellId].threshold, rowId, cellId)
            .then(initializeTable)
            .then(function (_a) {
            var month_totals = _a.month_totals, year_totals = _a.year_totals;
            setYearTotals(year_totals);
            setMonthTotals(month_totals);
            setIsLoading(false);
        });
    }; };
    var handleCopyForward = function (rowId, cellId) { return function () {
        var _a;
        var cellValue = collection[rowId].cells[cellId].value;
        var row = __assign({}, collection[rowId]);
        var _b = row.cells[cellId], month = _b.month, value = _b.value;
        row.cells = Object.entries(row.cells).reduce(function (cells, _a) {
            var _b;
            var id = _a[0], cell = _a[1];
            return (__assign(__assign({}, cells), (_b = {}, _b[id] = __assign(__assign({}, cell), { value: cell.month > month ? cellValue : cell.value }), _b)));
        }, {});
        setCollection(__assign(__assign({}, collection), (_a = {}, _a[rowId] = row, _a)));
        onCellCopyForward(value, rowId, cellId, month);
    }; };
    var handleCopyForwardThreshold = function (rowId, cellId) { return function () {
        var _a;
        var cellValue = collection[rowId].cells[cellId].threshold;
        var row = __assign({}, collection[rowId]);
        var _b = row.cells[cellId], month = _b.month, threshold = _b.threshold;
        row.cells = Object.entries(row.cells).reduce(function (cells, _a) {
            var _b;
            var id = _a[0], cell = _a[1];
            return (__assign(__assign({}, cells), (_b = {}, _b[id] = __assign(__assign({}, cell), { threshold: cell.month > month ? cellValue : cell.threshold }), _b)));
        }, {});
        setCollection(__assign(__assign({}, collection), (_a = {}, _a[rowId] = row, _a)));
        onCellCopyForwardThreshold(threshold, rowId, cellId, month);
    }; };
    // presentation
    var renderTableRow = function (_a) {
        var rowId = _a[0], _b = _a[1], name = _b.name, cells = _b.cells;
        return (React.createElement("tr", { key: rowId },
            React.createElement("th", { className: "align-middle" }, name),
            Object.entries(cells).map(function (_a, index) {
                var cellId = _a[0], _b = _a[1], month = _b.month, threshold = _b.threshold, value = _b.value;
                var isNewYear = index !== 0 && index % 12 === 0;
                return (React.createElement(React.Fragment, { key: index },
                    React.createElement(Cell, { className: isNewYear ? 'yearDivider' : '', value: threshold, onChange: handleCellThresholdUpdate(rowId, cellId), onBlur: onCellThresholdBlur(rowId, cellId), cellHint: '\u00A3', onCopyForward: handleCopyForwardThreshold(rowId, cellId) }),
                    React.createElement(Cell, { value: value, onChange: handleCellValueUpdate(rowId, cellId), onBlur: onCellValueBlur(rowId, cellId), cellHint: '%', onCopyForward: handleCopyForward(rowId, cellId) })));
            })));
    };
    var renderTable = function () {
        if (Object.values(collection).length === 0)
            return noRowsToDisplay;
        else if (monthsForYear.length === 0 || Object.values(Object.values(collection)[0].cells).length === 0)
            return noMonthsToDisplay();
        else
            return (React.createElement(React.Fragment, null, Object.entries(collection).map(renderTableRow)));
    };
    // view
    return (React.createElement("div", { className: "card" },
        React.createElement("div", { className: "card-header d-flex justify-content-between" },
            React.createElement("div", { className: "align-self-center" }, tableHeader)),
        React.createElement("div", { className: "table-scroll data-table" },
            React.createElement("table", { className: "table table-bordered" },
                React.createElement("thead", null,
                    React.createElement(TaxAssumptionsHeaderRow, { monthsForYear: monthsForYear, type: columnsHeader, timePeriodTotals: timePeriodTotals })),
                React.createElement("tbody", null, renderTable()))),
        isLoading &&
            React.createElement("div", { className: "data-table-loading" },
                React.createElement("div", { className: "spinner-border text-light", role: "status" },
                    React.createElement("span", { className: "sr-only" }, "Loading...")))));
}
// technically impossible
var noRowsToDisplay = (React.createElement("tr", null,
    React.createElement("th", { className: 'text-center py-4 text-wrap' },
        React.createElement("p", null, "No tax types to display."),
        React.createElement("p", { className: 'mb-0' },
            React.createElement("a", { href: '/tax_types/new' }, "Click here"),
            " to create a new tax type."))));
var noMonthsToDisplay = function () { return (React.createElement("tr", null,
    React.createElement("th", { className: 'text-center py-4 text-wrap' },
        React.createElement("p", null, "No tax assumptions to display.")))); };
var tableHeader = (React.createElement("div", null,
    React.createElement("i", { className: "fas fa-percent fa-fw text-muted mr-2" }),
    "Tax Assumptions"));
export default function TaxAssumptionsDataTable() {
    var _this = this;
    var initializeTable = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, TaxAssumptionsApi.fetchTaxAssumptions()];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    }); };
    var commitCellUpdate = function (newValue, rowId, cellId) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, TaxAssumptionsApi.updateTaxAssumption(cellId, newValue)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var commitCellThresholdUpdate = function (newThreshold, rowId, cellId) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, TaxAssumptionsApi.updateTaxAssumptionThreshold(cellId, newThreshold)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var onCellCopyForward = function (newValue, rowId, cellId, month) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, DataTablesApi.copyForward(rowId, 'TaxType', 'tax_assumptions', newValue, month.toString())];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var onCellCopyForwardThreshold = function (newThreshold, rowId, cellId, month) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, TaxAssumptionsApi.copyForwardThreshold(rowId, 'TaxType', 'tax_assumptions', newThreshold, month.toString())];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    return React.createElement(DataTable, { initializeTable: initializeTable, columnsHeader: 'Tax', tableHeader: tableHeader, commitCellUpdate: commitCellUpdate, commitCellThresholdUpdate: commitCellThresholdUpdate, noRowsToDisplay: noRowsToDisplay, noMonthsToDisplay: noMonthsToDisplay, onCellCopyForward: onCellCopyForward, onCellCopyForwardThreshold: onCellCopyForwardThreshold, timePeriodTotals: TimePeriodTotals.None });
}
