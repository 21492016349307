$(function() {
  $('#welcome-modal [data-user-onboarded]').on('click', function() {
    $.ajax({
       type: 'PATCH',
       url: $(this).data('user-onboarded'),
       data: { user: { user_onboarded: true } },
       processData: true,
    });
  });
});
