var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import moment from "moment";
import Cell from "./data-table/Cell";
import FooterRow from "./data-table/FooterRow";
import HeaderRow from "./data-table/HeaderRow";
import { monthRange } from "../utils/dates";
import { parseNumber, numberWithCommas } from "../../misc/number_formatter";
export var TimePeriodTotals;
(function (TimePeriodTotals) {
    TimePeriodTotals[TimePeriodTotals["None"] = 0] = "None";
    TimePeriodTotals[TimePeriodTotals["Monthly"] = 1] = "Monthly";
    TimePeriodTotals[TimePeriodTotals["Yearly"] = 2] = "Yearly";
    TimePeriodTotals[TimePeriodTotals["Both"] = 3] = "Both";
})(TimePeriodTotals || (TimePeriodTotals = {}));
export default function DataTable(_a) {
    var cellHint = _a.cellHint, columnsHeader = _a.columnsHeader, tableHeader = _a.tableHeader, noRowsToDisplay = _a.noRowsToDisplay, noMonthsToDisplay = _a.noMonthsToDisplay, initializeTable = _a.initializeTable, commitCellUpdate = _a.commitCellUpdate, onCellUpdate = _a.onCellUpdate, onCellCopyForward = _a.onCellCopyForward, timePeriodTotals = _a.timePeriodTotals, readOnly = _a.readOnly, yearTotalsEvaluator = _a.yearTotalsEvaluator, monthTotalsEvaluator = _a.monthTotalsEvaluator, hasOpeningBalance = _a.hasOpeningBalance, headerMonthFormat = _a.headerMonthFormat, rowHeaderContent = _a.rowHeaderContent;
    var _b = React.useState({}), collection = _b[0], setCollection = _b[1];
    var _c = React.useState(moment('2021-01-01')), startMonth = _c[0], setStartMonth = _c[1];
    var _d = React.useState(moment('2021-12-01')), endMonth = _d[0], setEndMonth = _d[1];
    var _e = React.useState(false), isLoading = _e[0], setIsLoading = _e[1];
    var monthsForYear = monthRange(startMonth, endMonth);
    React.useEffect(function () {
        setIsLoading(true);
        initializeTable().then(function (_a) {
            var start_month = _a.start_month, end_month = _a.end_month, collection = _a.collection, month_totals = _a.month_totals, year_totals = _a.year_totals;
            setCollection(collection);
            setStartMonth(moment(start_month));
            setEndMonth(moment(end_month));
            setIsLoading(false);
        });
    }, []);
    // handlers
    var handleCellUpdate = function (rowId, cellId) { return function (newValue) {
        var collectionCopy = __assign({}, collection);
        collectionCopy[rowId].cells[cellId].value = newValue;
        setCollection(collectionCopy);
        if (onCellUpdate)
            onCellUpdate(newValue, rowId, cellId);
    }; };
    var onCellBlur = function (rowId, cellId) { return function () {
        if (commitCellUpdate) {
            setIsLoading(true);
            commitCellUpdate(collection[rowId].cells[cellId].value, rowId, cellId)
                .then(initializeTable)
                .then(function (_a) {
                var collection = _a.collection;
                setCollection(collection);
                setIsLoading(false);
            });
        }
    }; };
    var handleCopyForward = function (rowId, cellId) { return function () {
        var _a;
        if (onCellCopyForward) {
            setIsLoading(true);
            var cellValue_1 = collection[rowId].cells[cellId].value;
            var row = __assign({}, collection[rowId]);
            var resourceId = row.resource_id;
            var resourceType = row.resource_type;
            var toMonth = row.end_date ? moment(row.end_date) : undefined;
            var _b = row.cells[cellId], month_1 = _b.month, value = _b.value;
            row.cells = Object.entries(row.cells).reduce(function (cells, _a) {
                var _b;
                var id = _a[0], cell = _a[1];
                return (__assign(__assign({}, cells), (_b = {}, _b[id] = __assign(__assign({}, cell), { value: cell.month > month_1 ? cellValue_1 : cell.value }), _b)));
            }, {});
            setCollection(__assign(__assign({}, collection), (_a = {}, _a[rowId] = row, _a)));
            onCellCopyForward(value, rowId, cellId, month_1, resourceType, resourceId, toMonth)
                .then(initializeTable)
                .then(function (_a) {
                var collection = _a.collection;
                setCollection(collection);
                setIsLoading(false);
            });
        }
    }; };
    // presentation
    var renderTableRow = function (_a) {
        var _b = _a, rowId = _b[0], _c = _b[1], name = _c.name, link = _c.link, cells = _c.cells, editable = _c.editable, opening_balance = _c.opening_balance, otherProps = __rest(_c, ["name", "link", "cells", "editable", "opening_balance"]);
        return (React.createElement("tr", { key: rowId },
            React.createElement("th", { className: "align-middle" }, rowHeaderContent ? (rowHeaderContent({ name: name, link: link, otherProps: otherProps, cells: cells })) : (React.createElement(React.Fragment, null,
                link && React.createElement("a", { href: link, className: 'text-wrap' }, name),
                (link == undefined || link === '') && name))),
            Object.entries(cells).map(function (_a, index) {
                var cellId = _a[0], _b = _a[1], month = _b.month, value = _b.value;
                var isNewYear = index !== 0 && index % 12 === 0;
                var isEndOfYear = index % 12 === 11;
                var isFirstMonth = index === 0;
                var yearTotal = '';
                if (isEndOfYear) {
                    if (yearTotalsEvaluator) {
                        yearTotal = yearTotalsEvaluator((index + 1) / 12, rowId);
                    }
                    else {
                        yearTotal = numberWithCommas(Object.entries(cells).slice(index - 11, index + 1)
                            .map(function (_a, index) {
                            var cellId = _a[0], _b = _a[1], month = _b.month, value = _b.value;
                            return parseNumber(value.toString());
                        })
                            .reduce(function (sum, current) { return sum + current; }, 0));
                    }
                }
                return (React.createElement(React.Fragment, { key: index },
                    hasOpeningBalance && isFirstMonth && React.createElement("th", { className: 'cell' },
                        React.createElement("div", { className: 'cell-no-padding' },
                            React.createElement("div", { className: 'cell-width' }),
                            cellHint && React.createElement("span", { className: "cell-hint-text" }, cellHint),
                            React.createElement("input", { type: "text", readOnly: true, className: "no-highlight total-input", value: opening_balance, style: { width: "100%", textAlign: 'right' } }))),
                    React.createElement(Cell, { className: isNewYear ? 'yearDivider' : '', value: value, onChange: handleCellUpdate(rowId, cellId), onBlur: onCellBlur(rowId, cellId), cellHint: cellHint, onCopyForward: handleCopyForward(rowId, cellId), readOnly: readOnly || editable === false }),
                    isEndOfYear && (timePeriodTotals === TimePeriodTotals.Both || timePeriodTotals === TimePeriodTotals.Yearly) && React.createElement("th", { className: 'yearDivider cell' },
                        React.createElement("div", { className: 'cell-no-padding' },
                            React.createElement("div", { className: 'cell-width' }),
                            cellHint && React.createElement("span", { className: "cell-hint-text" }, cellHint),
                            React.createElement("input", { type: "text", readOnly: true, className: "no-highlight total-input", value: yearTotal, style: { width: "100%", textAlign: 'right' } })))));
            })));
    };
    var renderTable = function () {
        if (Object.values(collection).length === 0)
            return noRowsToDisplay;
        else if ((monthsForYear.length === 0 || Object.values(Object.values(collection)[0].cells).length === 0) && noMonthsToDisplay)
            return noMonthsToDisplay();
        else
            return (React.createElement(React.Fragment, null, Object.entries(collection).map(renderTableRow)));
    };
    // view
    return (React.createElement("div", { className: "card" },
        React.createElement("div", { className: "card-header d-flex justify-content-between" },
            React.createElement("div", { className: "align-self-center" }, tableHeader)),
        React.createElement("div", { className: "table-scroll data-table" },
            React.createElement("table", { className: "table table-bordered", style: { tableLayout: 'fixed' } },
                React.createElement("thead", null,
                    React.createElement(HeaderRow, { monthsForYear: monthsForYear, type: columnsHeader, timePeriodTotals: timePeriodTotals, hasOpeningBalance: hasOpeningBalance, monthFormat: headerMonthFormat })),
                React.createElement("tbody", null, renderTable()),
                (timePeriodTotals === TimePeriodTotals.Both || timePeriodTotals === TimePeriodTotals.Monthly) &&
                    React.createElement("tfoot", null,
                        React.createElement(FooterRow, { collection: collection, monthsForYear: monthsForYear, cellHint: cellHint, timePeriodTotals: timePeriodTotals, yearTotalsEvaluator: yearTotalsEvaluator, monthTotalsEvaluator: monthTotalsEvaluator, hasOpeningBalance: hasOpeningBalance })))),
        isLoading &&
            React.createElement("div", { className: "data-table-loading" },
                React.createElement("div", { className: "spinner-border text-light", role: "status" },
                    React.createElement("span", { className: "sr-only" }, "Loading...")))));
}
