// Turn <input type='currency' /> elements into an actual currency input.
// https://stackoverflow.com/a/54534797/139299

export function currencyToNumber(s) {
  if (typeof s === 'number')
    return s;

  return Number(String(s).replace(/[^0-9.-]+/g, ''));
}

export function numberToCurrency(value, includeDecimals = true) {
  const currency = 'GBP';
  const options = {
    minimumFractionDigits : (includeDecimals ? 2 : 0),
    maximumFractionDigits : (includeDecimals ? 2 : 0),
    currency,
    style                 : 'currency',
    currencyDisplay       : 'symbol'
  };
  return (value || value === 0)
    ? currencyToNumber(value).toLocaleString(undefined, options)
    : (includeDecimals ? '£0.00' : '£0');
}

export function isZero(value) {
  return numberToCurrency(value, false) == '£0';
}

$(function() {
  function elementsAsCurrency(elements) {
    $.each(elements, function() {
      const value = $(this).val();
      $(this).val(numberToCurrency(value));
    });
  }

  function onFocus(e) {
    const value = e.target.value;
    e.target.value = value ? currencyToNumber(value).toFixed(2) : '';
    e.target.select();
  }

  function onBlur(e) {
    const value = e.target.value;
    e.target.value = numberToCurrency(value);
  }

  // Apply the events to page loaded elements.
  const currencyElements = $('input[type="currency"]');
  elementsAsCurrency(currencyElements);
  currencyElements.on('focus', onFocus);
  currencyElements.on('blur', onBlur);

  // Apply the events to cocoon generated elements.
  $(document).on('cocoon:after-insert', '.cocoon-container:has(input[type="currency"])', function(e, insertedItem) {
    const currencyElements = $(insertedItem).find('input[type="currency"]');
    elementsAsCurrency(currencyElements);
    currencyElements.on('focus', onFocus);
    currencyElements.on('blur', onBlur);
  });
});
