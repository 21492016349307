import sendAnalyticsEvent from '../misc/send-analytics-event'

$(function() {
  if (!$('#analytics-data').data('events')) {
    return;
  }

  $('#analytics-data').data('events').forEach(function(event) {
    sendAnalyticsEvent(event);
  })
});
