// Format <input type='percentage' /> inputs with a '%' symbol.

import { parseNumber } from './number_formatter'

export function percentageToNumber(value) {
  return parseNumber(value)
}

export function numberToPercentage(value, includeDecimals = true) {
  const options = {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: (includeDecimals ? 2 : 0),
  };
  return (value || value === 0)
    ? (percentageToNumber(value) / 100).toLocaleString(undefined, options)
    : (includeDecimals ? '0.00%' : '0%');
}

$(function() {
  function elementsAsPercentage(elements) {
    $.each(elements, function() {
      const value = $(this).val();
      $(this).val(numberToPercentage(value));
    });
  }

  function onFocus(e) {
    const value = e.target.value;
    e.target.value = value ? percentageToNumber(value) : '';
    e.target.select();
  }

  function onBlur(e) {
    const value = e.target.value;
    e.target.value = numberToPercentage(value);
  }

  // Apply the events to page loaded elements.
  const percentageElements = $('input[type="percentage"]');
  elementsAsPercentage(percentageElements);
  percentageElements.on('focus', onFocus);
  percentageElements.on('blur', onBlur);

  // Apply the events to cocoon generated elements.
  $(document).on('cocoon:after-insert', '.cocoon-container:has(input[type="percentage"])', function(e, insertedItem) {
    const percentageElements = $(insertedItem).find('input[type="percentage"]');
    elementsAsPercentage(percentageElements);
    percentageElements.on('focus', onFocus);
    percentageElements.on('blur', onBlur);
  });
});
