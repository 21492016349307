var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import * as React from 'react';
import CreditorRow from './CreditorRow';
import { emptyCreditor } from '../../modules/opening-balance-sheet/types';
function CreditorsTable(_a) {
    var creditors = _a.creditors, setParent = _a.setParent;
    var _b = React.useState(creditors), propCreditors = _b[0], setCreditors = _b[1];
    var addCreditor = function () {
        var newCreditors = propCreditors.concat(emptyCreditor());
        setCreditors(newCreditors);
        setParent(newCreditors);
    };
    var removeCreditor = function (id) { return function () {
        updateCreditors(id)('_destroy', true);
    }; };
    var updateCreditors = function (id) { return function (field, value) {
        var clone = __spreadArrays(propCreditors);
        for (var i in clone) {
            if (clone[i].id == id) {
                clone[i][field] = value;
                break;
            }
        }
        setCreditors(clone);
        setParent(clone);
    }; };
    var activeCreditors = function () { return propCreditors.filter(function (creditor) { return !creditor._destroy; }); };
    return React.createElement(React.Fragment, null,
        React.createElement("table", { className: 'table table-borderless table-sm' },
            React.createElement("tbody", null, activeCreditors().map(function (creditor) {
                return React.createElement(CreditorRow, { key: creditor.id, creditor: creditor, handleRemoveRow: removeCreditor(creditor.id), setParent: updateCreditors(creditor.id) });
            })),
            React.createElement("tfoot", null,
                React.createElement("tr", null,
                    React.createElement("td", { className: 'w32p text-right' },
                        React.createElement("a", { className: 'btn btn-light border', onClick: addCreditor },
                            React.createElement("i", { className: 'fas fa-plus mr-2' }),
                            "Add Creditor")),
                    React.createElement("td", { className: 'w21p' }),
                    React.createElement("td", null)))));
}
export default CreditorsTable;
