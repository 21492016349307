import * as React from "react";
import SalesDataTable from './sales/SalesDataTable';
import MarginDataTable from './sales/MarginDataTable';
import GrossProfitDataTable from './sales/GrossProfitDataTable';
export default function SalesDataTables() {
    var onTableChange = function () {
        document.dispatchEvent(new CustomEvent('data_table:change'));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: 'sales-data-table', className: "mb-4" },
            React.createElement(SalesDataTable, { onTableChange: onTableChange })),
        React.createElement("div", { id: 'margin-data-table', className: "mb-4" },
            React.createElement(MarginDataTable, { onTableChange: onTableChange })),
        React.createElement("div", { id: 'gross-profit-data-table', className: "mb-4" },
            React.createElement(GrossProfitDataTable, null))));
}
