import $ from 'jquery';
import Chart from 'chart.js';
import _ from 'chartjs-plugin-colorschemes';
import moment from 'moment';
import { numberToCurrency } from 'misc/currency';
import { awaitCalculationJobs } from './await-calculation-jobs'

$(() => {
  if (!$("[data-report-chart]").length)
    return;

  $('[data-hide-during-calculation-jobs]').hide()

  awaitCalculationJobs().then(() => {
    $('[data-hide-during-calculation-jobs]').show()

    // Wait for all charts to be drawn, then add a div to the DOM.
    Promise.all(
      $('[data-report-chart]').map(initializePeriodChart)
    ).then(() => {
      setTimeout(() => {
        $('body').append('<div id="charts-complete" class="d-none"></div>');
      }, 2000); // Give Dhalang time to do its thing.
    });
  })

  function timeFormat(timePeriod) {
    switch(timePeriod) {
      case 'quarter':
        return 'YYYY [Q]Q';
      case 'year':
        return 'YYYY';
      default:
        return 'MMM YYYY';
    }
  }

  function labelsAsMoment(labels, timePeriod) {
    return $.map(labels, function(val, i) {
      if (timePeriod == 'all') return 'Lifetime';
      let formatted =  moment(val).format(timeFormat(timePeriod));
      if (timePeriod == 'year' || timePeriod == 'quarter') {
        formatted = 'Year ' + formatted.replace(/^0+/g, '');
      }
      return formatted
    });
  }

  function initializePeriodChart() {
    const chart = $(this)
    const dataUrl = chart.data('report-chart');
    const ctx = this.getContext('2d');
    const chartLoading = chart.closest('.card-body').find('.chart-loading');
    const noResults = chart.closest('.card-body').find('.no-results');
    const timePeriod = chart.data('time-period');

    return new Promise((resolve) => {
      $.getJSON(dataUrl, function(data) {
        chartLoading.hide();

        if (data.datasets.length > 0) {
          new Chart(ctx, {
            type: data.type,
            data: {
              labels: labelsAsMoment(data.labels, timePeriod),
              datasets: data.datasets.map((dataset) => { return { ...dataset, fill: false } })
            },
            options: {
              legend: {
                display: false
              },
              plugins: {
                colorschemes: {
                  scheme: 'tableau.ClassicBlueRed12'
                }
              },
              responsive: true,
              animation: {
                duration: 0
              },
              scales: {
                yAxes: [{
                  ticks: {
                    beginAtZero: false,
                    callback: function(value, index, values) {
                      return numberToCurrency(value, false);
                    }
                  }
                }]
              },
              tooltips: {
                callbacks: {
                  label: function(tooltipItem, data) {
                    return numberToCurrency(tooltipItem.value);
                  }
                }
              }
            }
          });
        } else {
          noResults.removeClass('d-none');
        }
      }).then(resolve);
    });
  }
});
