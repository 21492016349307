import $ from 'jquery';
import _ from 'lodash';
require('@rails/ujs').start();
require('@rails/activestorage').start();
require("@nathanvda/cocoon")
const jquery = require("jquery")
require('channels');
require('data-confirm-modal');

const images = require.context('../images', true);

window.$ = window.jQuery = jquery;

import 'bootstrap/dist/js/bootstrap';

import '../sb-admin-pro/js/scripts';
import '../misc/currency';
import '../misc/percentage';
import '../misc/datetimepicker';
import '../misc/flash-modal';
import '../misc/field-dependency';
import '../misc/analytics-events';
import '../misc/push-state';
import '../misc/welcome-modal';
import '../misc/toggle-password-visibility';
import '../staff/toggle';
import '../reports/charts';
import '../reports/chart-form';
import '../reports/await-calculation-jobs';
import '../products/create-form';


// Support component names relative to this directory:
const componentRequireContext = require.context("compass-sheet", true);
const ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

$(document).ready(function() {
    $('form[data-please-wait-modal]').submit(function() {
        $(this).find(':input[type=submit]').prop('disabled', true);
        $('#please-wait-modal').modal({
            backdrop: 'static'
        });
    });
    $('[role="refresh-page').click(function() {
       window.location = $(this).data('refresh-page-url');
    });
});

function recaptchaExpiredCallback() {
    $('#recaptcha-modal').modal({
        backdrop: 'static',
        keyboard: false,
        focus: true
    });
}
