var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { CurrencyInput } from '../../components/CurrencyInput';
function ExistingLoanRow(props) {
    var _a = React.useState(props.existingLoan), existingLoan = _a[0], setExistingLoan = _a[1];
    var updateField = function (field) { return function (e) {
        var value = e.target.value;
        updateFieldValue(field)(value);
    }; };
    var updateFieldValue = function (field) { return function (value) {
        var clone = __assign({}, existingLoan);
        clone[field] = value;
        setExistingLoan(clone);
        props.setParent(field, value);
    }; };
    return (React.createElement("tr", { id: existingLoan.id },
        React.createElement("td", { className: 'pl-0' },
            React.createElement("input", { value: existingLoan.name, onChange: updateField('name'), className: 'form-control string' })),
        React.createElement("td", null,
            React.createElement(CurrencyInput, { value: existingLoan.amountOutstanding, setHook: updateFieldValue('amountOutstanding') })),
        React.createElement("td", null,
            React.createElement(CurrencyInput, { value: existingLoan.monthlyFixedPayment, setHook: updateFieldValue('monthlyFixedPayment') })),
        React.createElement("td", null,
            React.createElement("input", { type: 'number', value: existingLoan.noOfMonthsRemaining, onChange: updateField('noOfMonthsRemaining'), className: 'form-control string', min: 1 })),
        React.createElement("td", { className: 'align-middle pr-0' },
            React.createElement("a", { className: 'btn btn-sm btn-light border py-2', onClick: props.handleRemoveRow },
                React.createElement("i", { className: 'fas fa-fw fa-trash' })))));
}
export default ExistingLoanRow;
