import * as React from 'react';
import { TimePeriodTotals } from "../DataTable";
export default function HeaderRow(_a) {
    var type = _a.type, monthsForYear = _a.monthsForYear, timePeriodTotals = _a.timePeriodTotals, hasOpeningBalance = _a.hasOpeningBalance, monthFormat = _a.monthFormat;
    return (React.createElement("tr", null,
        React.createElement("th", { style: { width: '200px' } }, type),
        monthsForYear.map(function (month, index) {
            var isNewYear = index !== 0 && index % 12 === 0;
            var isEndOfYear = index % 12 === 11;
            var formattedMonth = month.format(monthFormat || 'MMM YYYY');
            var isFirstMonth = index === 0;
            return (React.createElement(React.Fragment, { key: month.toString() },
                hasOpeningBalance && isFirstMonth && (React.createElement("th", { style: { textAlign: 'center' } }, "Opening Balance")),
                React.createElement("th", { className: isNewYear ? 'yearDivider' : '', style: { textAlign: 'center' } }, formattedMonth),
                (timePeriodTotals === TimePeriodTotals.Both || timePeriodTotals === TimePeriodTotals.Yearly) && isEndOfYear && React.createElement("th", { className: 'yearDivider' })));
        })));
}
