export function parseNumber(value, locales = navigator.languages) {
  if (value === undefined)
    return 0;

  const example = Intl.NumberFormat(locales).format('1.1');
  const cleanPattern = new RegExp(`[^-+0-9${example.charAt(1)}]`, 'g');
  const cleaned = value.replace(cleanPattern, '');
  const normalized = cleaned.replace(example.charAt(1), '.');

  return numberIfNaN(parseFloat(normalized), 0);
}

export function numberWithCommas(value, numberOfDecimals = 0) {
  const options = {
    minimumFractionDigits: numberOfDecimals,
    maximumFractionDigits: numberOfDecimals,
  };
  return value.toLocaleString(undefined, options);
}

export function numberIfNaN(value, fallback = 0) {
  return isNaN(value) ? fallback : value;
}