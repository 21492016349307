var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import * as React from 'react';
import ExistingLoanRow from './ExistingLoanRow';
import { emptyExistingLoan } from '../../modules/opening-balance-sheet/types';
function ExistingLoansCard(_a) {
    var existingLoans = _a.existingLoans, setParent = _a.setParent;
    var _b = React.useState(existingLoans), propExistingLoans = _b[0], setExistingLoans = _b[1];
    var addExistingLoan = function () {
        var newExistingLoans = propExistingLoans.concat(emptyExistingLoan());
        setExistingLoans(newExistingLoans);
        setParent(newExistingLoans);
    };
    var removeExistingLoan = function (id) { return function () {
        updateExistingLoans(id)('_destroy', true);
    }; };
    var updateExistingLoans = function (id) { return function (field, value) {
        var clone = __spreadArrays(propExistingLoans);
        for (var i in clone) {
            if (clone[i].id == id) {
                clone[i][field] = value;
                break;
            }
        }
        setParent(clone);
    }; };
    var activeExistingLoans = function () { return propExistingLoans.filter(function (loan) { return !loan._destroy; }); };
    var tableHead = function () {
        return (React.createElement("thead", null,
            React.createElement("tr", null,
                React.createElement("th", { className: 'w32p' }, "Name"),
                React.createElement("th", { className: 'w21p' }, "Amount Outstanding"),
                React.createElement("th", { className: 'w21p' }, "Monthly Fixed Payment"),
                React.createElement("th", { className: 'w21p' }, "Months Remaining"),
                React.createElement("th", { className: 'w5p' }))));
    };
    return (React.createElement("table", { className: 'table table-borderless table-sm' },
        activeExistingLoans().length > 0 && tableHead(),
        React.createElement("tbody", null, activeExistingLoans().map(function (existingLoan) {
            return React.createElement(ExistingLoanRow, { key: existingLoan.id, existingLoan: existingLoan, handleRemoveRow: removeExistingLoan(existingLoan.id), setParent: updateExistingLoans(existingLoan.id) });
        })),
        React.createElement("tfoot", null,
            React.createElement("tr", null,
                React.createElement("td", { className: 'w32p text-right' },
                    React.createElement("a", { className: 'btn btn-light border', onClick: addExistingLoan },
                        React.createElement("i", { className: 'fas fa-plus mr-2' }),
                        "Add Existing Loan")),
                React.createElement("td", { colSpan: 4 })))));
}
export default ExistingLoansCard;
