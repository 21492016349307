var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { CurrencyInputWithLabel, CurrencyInput } from '../../components/CurrencyInput';
import { isZero, currencyToNumber } from '../../../misc/currency';
import CreditorsTable from './CreditorsTable';
import ExistingLoansTable from './ExistingLoansTable';
function CurrentLiabilitiesCard(props) {
    var _a = React.useState(props.openingBalanceSheet.currentLiabilities), currentLiabilities = _a[0], setCurrentLiabilities = _a[1];
    var _b = React.useState({}), error = _b[0], setError = _b[1];
    var validateTradeCreditorsTotal = function (liabilities) {
        var total = [
            currencyToNumber(liabilities.tradeCreditorsMonth1),
            currencyToNumber(liabilities.tradeCreditorsMonth2),
            currencyToNumber(liabilities.tradeCreditorsMonth3),
            currencyToNumber(liabilities.tradeCreditorsMonth4),
        ].reduce(function (sum, month) { return sum + month; });
        if (total !== currencyToNumber(liabilities.tradeCreditorsTotal)) {
            setError(__assign(__assign({}, error), { tradeCreditorsTotal: 'Paid month splits must equal the total amount.' }));
        }
        else {
            setError(__assign(__assign({}, error), { tradeCreditorsTotal: undefined }));
        }
    };
    var updateField = function (field) { return function (value) {
        var clone = __assign({}, currentLiabilities);
        clone[field] = value;
        setCurrentLiabilities(clone);
        props.setParent(clone);
        validateTradeCreditorsTotal(clone);
    }; };
    return (React.createElement("div", { className: 'card mb-4' },
        React.createElement("div", { className: 'card-header' }, "Current Liabilities"),
        React.createElement("div", { className: 'card-body' },
            React.createElement(CurrencyInputWithLabel, { label: 'Overdraft', value: currentLiabilities.overdraft, setHook: updateField('overdraft'), disabled: !isZero(props.openingBalanceSheet.currentAssets.cashInBank) }),
            React.createElement("div", { className: 'form-row form-group' },
                React.createElement("div", { className: 'col-2 col-form-label', style: { marginTop: '30px' } },
                    React.createElement("label", null, "Trade Creditors")),
                React.createElement("div", { className: 'col-2' },
                    React.createElement("label", null,
                        React.createElement("strong", null, "Total")),
                    React.createElement(CurrencyInput, { value: currentLiabilities.tradeCreditorsTotal, setHook: updateField('tradeCreditorsTotal') })),
                React.createElement("div", { className: 'col-2' },
                    React.createElement("label", null,
                        React.createElement("strong", null, "Paid month 1")),
                    React.createElement(CurrencyInput, { value: currentLiabilities.tradeCreditorsMonth1, setHook: updateField('tradeCreditorsMonth1'), className: error.tradeCreditorsTotal && 'is-invalid' })),
                React.createElement("div", { className: 'col-2' },
                    React.createElement("label", null,
                        React.createElement("strong", null, "Paid month 2")),
                    React.createElement(CurrencyInput, { value: currentLiabilities.tradeCreditorsMonth2, setHook: updateField('tradeCreditorsMonth2'), className: error.tradeCreditorsTotal && 'is-invalid' })),
                React.createElement("div", { className: 'col-2' },
                    React.createElement("label", null,
                        React.createElement("strong", null, "Paid month 3")),
                    React.createElement(CurrencyInput, { value: currentLiabilities.tradeCreditorsMonth3, setHook: updateField('tradeCreditorsMonth3'), className: error.tradeCreditorsTotal && 'is-invalid' })),
                React.createElement("div", { className: 'col-2' },
                    React.createElement("label", null,
                        React.createElement("strong", null, "Paid month 4")),
                    React.createElement(CurrencyInput, { value: currentLiabilities.tradeCreditorsMonth4, setHook: updateField('tradeCreditorsMonth4'), className: error.tradeCreditorsTotal && 'is-invalid' })),
                error.tradeCreditorsTotal && (React.createElement("div", { className: 'col offset-4' },
                    React.createElement("div", { className: "small text-danger" }, error.tradeCreditorsTotal)))),
            React.createElement(CurrencyInputWithLabel, { label: 'PAYE/NI/Pensions', value: currentLiabilities.payeNi, setHook: updateField('payeNi') }),
            props.openingBalanceSheet.showVat &&
                React.createElement(CurrencyInputWithLabel, { label: 'VAT', value: currentLiabilities.vat, setHook: updateField('vat') }),
            props.openingBalanceSheet.showCorporationTax &&
                React.createElement(CurrencyInputWithLabel, { label: 'Corporation Tax', value: currentLiabilities.corporationTax, setHook: updateField('corporationTax') }),
            React.createElement("div", { className: 'row' },
                React.createElement("div", { className: 'col-2 col-form-label' }, "Other Creditors"),
                React.createElement("div", { className: 'col-9' },
                    React.createElement(CreditorsTable, { creditors: currentLiabilities.creditors, setParent: updateField('creditors') }))),
            React.createElement("div", { className: 'row' },
                React.createElement("div", { className: 'col-2 col-form-label' }, "Existing Loans"),
                React.createElement("div", { className: 'col-9' },
                    React.createElement(ExistingLoansTable, { existingLoans: currentLiabilities.existingLoans, setParent: updateField('existingLoans') }))),
            React.createElement(CurrencyInputWithLabel, { label: 'Auto-Balance Correction', value: currentLiabilities.autoBalanceCorrection, setHook: updateField('autoBalanceCorrection'), disabled: true }),
            React.createElement("strong", null,
                React.createElement(CurrencyInputWithLabel, { label: 'Total', value: currentLiabilities.total, setHook: updateField('total'), disabled: true })))));
}
export default CurrentLiabilitiesCard;
