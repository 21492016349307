import * as React from 'react';
export default function TaxAssumptionsHeaderRow(_a) {
    var type = _a.type, monthsForYear = _a.monthsForYear, timePeriodTotals = _a.timePeriodTotals;
    return (React.createElement(React.Fragment, null,
        React.createElement("tr", null,
            React.createElement("th", null),
            monthsForYear.map(function (month, index) {
                var isNewYear = index !== 0 && index % 12 === 0;
                var monthFormat = month.format('MMM YYYY');
                return (React.createElement(React.Fragment, { key: monthFormat },
                    React.createElement("th", { className: isNewYear ? 'yearDivider' : '', style: { textAlign: 'center' }, colSpan: 2 }, monthFormat)));
            })),
        React.createElement("tr", null,
            React.createElement("th", null, type),
            monthsForYear.map(function (month, index) {
                var isNewYear = index !== 0 && index % 12 === 0;
                var monthFormat = month.format('MMM YYYY');
                return (React.createElement(React.Fragment, { key: monthFormat },
                    React.createElement("th", { className: isNewYear ? 'yearDivider' : '', style: { textAlign: 'center' } }, "Threshold"),
                    React.createElement("th", { style: { textAlign: 'center' } }, "Rate")));
            }))));
}
