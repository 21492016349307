var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import { parseNumber, numberWithCommas } from "../../../misc/number_formatter";
export default function Cell(_a) {
    var className = _a.className, cellHint = _a.cellHint, value = _a.value, onChange = _a.onChange, onBlur = _a.onBlur, onCopyForward = _a.onCopyForward, readOnly = _a.readOnly, disableCopyForward = _a.disableCopyForward, otherProps = __rest(_a, ["className", "cellHint", "value", "onChange", "onBlur", "onCopyForward", "readOnly", "disableCopyForward"]);
    var _b = React.useState(false), inputIsFocused = _b[0], setInputIsFocused = _b[1];
    var _c = React.useState(false), hoveringOverActions = _c[0], setHoveringOverActions = _c[1];
    var _d = React.useState(false), dirty = _d[0], setDirty = _d[1];
    var handleOnChange = function (e) {
        onChange(e.target.value);
        setDirty(true);
    };
    var handleOnFocus = function (e) {
        setInputIsFocused(true);
        e.target.select();
    };
    var handleOnBlur = function () {
        setInputIsFocused(false);
        var newValue = value;
        if (cellHint === "£" || cellHint === "%") {
            var decimalPlaces = cellHint === "£" ? 0 : 2;
            newValue = numberWithCommas(parseNumber(value), decimalPlaces);
            onChange(newValue);
        }
        if (!hoveringOverActions && !readOnly && dirty) {
            onBlur(newValue);
            setDirty(false);
        }
    };
    // Make actions disappear when clicking action link
    var handleCopyForward = function () {
        setHoveringOverActions(false);
        onCopyForward(value);
        setInputIsFocused(false);
    };
    return (React.createElement("td", { className: (className ? className : '') + ' cell' },
        React.createElement("div", { className: 'cell-no-padding' },
            React.createElement("div", { className: 'cell-width' }),
            cellHint && React.createElement("span", { className: "cell-hint-text" }, cellHint),
            React.createElement("input", __assign({ type: "text", value: value, onChange: handleOnChange, onBlur: handleOnBlur, onFocus: handleOnFocus, style: { width: "100%", textAlign: 'right' }, className: "cell-value-input", readOnly: readOnly }, otherProps)),
            (inputIsFocused || hoveringOverActions) && !readOnly && !disableCopyForward &&
                React.createElement("div", { className: "cell-actions", onMouseEnter: function () { return setHoveringOverActions(true); }, onMouseLeave: function () { return setHoveringOverActions(false); } },
                    React.createElement("span", { className: "btn btn-sm btn-cell-action-copy-forward", onClick: handleCopyForward },
                        "Copy Forward",
                        React.createElement("i", { className: "fas fa-arrow-right ml-1" }))))));
}
