import moment from 'moment';
import 'knockout-daterangepicker/dist/daterangepicker.js';

$(function() {

  if (!$("#chart-form").length) return;

  $("#chart-form").on("change", function() {

    // User has changed the crtieria form since page load,
    // display modal warning that generated PDF will reflect
    // page load report values NOT values entered into
    // criteria box

    $('#btn-generate-pdf')[0].dataset.confirm = `
      You have changed the chart options without refreshing (<i class=\"fas fa-sync-alt\"></i>),
      these changes may not be reflected in the PDF.<br><br>
      Click confirm to generate using the information currently on screen.
    `;

    // Show user that form can be re-sent

    $('#btn-submit-charts').removeAttr('disabled').removeClass('disabled');
  });

  const inputStart = $('#charts-choose-start');
  const inputEnd   = $('#charts-choose-end');
  const picker     = $('#charts-choose-range');

  const dateRangeLabelFormat = () => {
    const granularity = $('#charts-select-granularity').val() || 'month';

    const formats = {
      'month':   'MMM YYYY',
      'year':    'YYYY',
      'quarter': '[Q]Q YYYY'
    };

    return formats[granularity];
  }

  const pickerRanges = (start, end) => {
    return {
      "Year 1": [start, moment(start).add(1, "year").subtract(1, "day")],
      "Year 2": [moment(start).add(1, "year"), moment(start).add(2, "year").subtract(1, "day")],
      "Year 3": [moment(start).add(2, "year"), moment(start).add(3, "year").subtract(1, "day")],
      "All":    [start, end]
    }
  }

  const setupDateRangePicker = () => {
    const minDate   = moment.utc(picker.data('company-start'));
    const maxDate   = moment.utc(picker.data('company-end'));
    const startDate = moment.utc(inputStart.val() || minDate, 'DD/MM/YYYY');
    const endDate   = moment.utc(inputEnd.val() || maxDate, 'DD/MM/YYYY');
    const ranges    = pickerRanges(minDate, maxDate);
    const format    = dateRangeLabelFormat();

    picker.val(`${startDate.format(format)} - ${endDate.format(format)}`)

    picker.daterangepicker({
      startDate,
      endDate,
      minDate,
      maxDate,
      ranges,
      expanded: true,
      orientation: 'left',
      period: $('#charts-select-granularity').val() || 'month',
      periods: ['month', 'quarter', 'year'],
      callback: function (start, end, period) {
        const format = dateRangeLabelFormat();
        picker.val(`${start.format(format)} - ${end.format(format)}`)

        inputStart.val(start.format('DD/MM/YYYY'));
        inputEnd.val(end.format('DD/MM/YYYY'));

        $("#chart-form").trigger("change");
      }
    });
  }

  // Change daterangepicker label format to reflect chosen granularity

  $('#charts-select-granularity').change(setupDateRangePicker);

  setupDateRangePicker();

});