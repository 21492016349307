import * as Http from "../compass-sheet/utils/http";

export async function awaitCalculationJobs() {
  return new Promise(function(resolve, reject) {
    (async function awaitComplete() {
      const [company,] = await Http.get('/company.json')
      if (!company.has_pending_calculation_jobs)
        return resolve()

      setTimeout(awaitComplete, 500);
    })();
  });
}

$(document).ready(function() {
  if (!$("[data-wait-for-calculation-jobs]").length)
    return;

  $('[data-hide-during-calculation-jobs]').hide()

  awaitCalculationJobs().then(() => {
    $('[data-hide-during-calculation-jobs]').show()
    $.get($("[data-wait-for-calculation-jobs]").data('show-url'))
  })
});
