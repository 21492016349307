var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { numberToCurrency, currencyToNumber } from '../../misc/currency';
import FixedAssetsCard from '../components/opening-balance-sheet/FixedAssetsCard';
import CurrentAssetsCard from '../components/opening-balance-sheet/CurrentAssetsCard';
import CurrentLiabilitiesCard from '../components/opening-balance-sheet/CurrentLiabilitiesCard';
import AdditionalFieldsCard from '../components/opening-balance-sheet/AdditionalFieldsCard';
import * as OpeningBalanceSheetApi from '../modules/opening-balance-sheet/api';
import { apiToState, stateToApi } from '../modules/opening-balance-sheet/interface-converter';
function OpeningBalanceSheet() {
    var _a;
    var _b = React.useState({}), data = _b[0], setData = _b[1];
    var _c = React.useState(), error = _c[0], setError = _c[1];
    var _d = React.useState(false), isLoaded = _d[0], setIsLoaded = _d[1];
    var _e = React.useState(false), submitting = _e[0], setSubmitting = _e[1];
    React.useEffect(function () {
        OpeningBalanceSheetApi.fetchOpeningBalanceSheet().then(function (result) {
            setData(apiToState(result));
            setIsLoaded(true);
        }, function (error) {
            setError({ message: 'Failed to load Opening Balance Sheet.' });
        });
    }, []);
    var updateDataField = function (field) { return function (value) {
        var clone = __assign({}, data);
        clone[field] = value;
        var unbalancedNetAssets = calculateUnbalancedNetAssets(clone);
        var equity = calculateEquity(clone.additionalFields);
        var assetsCorrection = calculateAssetsCorrection(equity, unbalancedNetAssets);
        var liabilitiesCorrection = calculateLiabilitiesCorrection(equity, unbalancedNetAssets);
        var totalAssets = calculateAssetsTotal(clone, assetsCorrection);
        var totalLiabilities = calculateCurrentLiabilitiesTotal(clone.currentLiabilities, liabilitiesCorrection);
        clone.currentAssets.autoBalanceCorrection = numberToCurrency(assetsCorrection);
        clone.currentLiabilities.autoBalanceCorrection = numberToCurrency(liabilitiesCorrection);
        clone.currentAssets.total = numberToCurrency(totalAssets);
        clone.currentLiabilities.total = numberToCurrency(totalLiabilities);
        clone.additionalFields.netAssets = calculateNetAssets(totalAssets, totalLiabilities);
        setData(clone);
    }; };
    var calculateAssetsTotal = function (openingBalanceSheet, assetsCorrection) {
        return calculateUnbalancedAssets(openingBalanceSheet) + assetsCorrection;
    };
    var calculateCurrentLiabilitiesTotal = function (currentLiabilities, liabilitiesCorrection) {
        return calculateUnbalancedLiabilities(currentLiabilities) + liabilitiesCorrection;
    };
    var calculateNetAssets = function (totalAssets, totalLiabilities) {
        return numberToCurrency(totalAssets - totalLiabilities);
    };
    var calculateEquity = function (additionalFields) {
        var reserves = currencyToNumber(additionalFields.reserves);
        var shareCapital = currencyToNumber(additionalFields.shareCapital);
        return reserves + shareCapital;
    };
    var calculateAssetsCorrection = function (equity, unbalancedNetAssets) {
        if (equity - unbalancedNetAssets < 0) {
            return 0;
        }
        return equity - unbalancedNetAssets;
    };
    var calculateLiabilitiesCorrection = function (equity, unbalancedNetAssets) {
        if (equity - unbalancedNetAssets > 0) {
            return 0;
        }
        return unbalancedNetAssets - equity;
    };
    var calculateUnbalancedCurrentAssets = function (currentAssets) {
        var values = [
            Number(currencyToNumber(currentAssets.stock)),
            Number(currencyToNumber(currentAssets.tradeDebtorsTotal)),
            Number(currencyToNumber(currentAssets.cashInBank)),
        ];
        var total = values.reduce(function (sum, value) { return sum + value; });
        currentAssets.debtors.forEach(function (debtor) {
            if (!debtor._destroy) {
                total += currencyToNumber(debtor.amount);
            }
        });
        return total;
    };
    var calculateUnbalancedAssets = function (openingBalanceSheet) {
        var fixedAssetsTotal = 0;
        openingBalanceSheet.fixedAssets.forEach(function (fixedAsset) {
            if (!fixedAsset._destroy) {
                fixedAssetsTotal += currencyToNumber(fixedAsset.bookValue);
            }
        });
        return calculateUnbalancedCurrentAssets(openingBalanceSheet.currentAssets) + fixedAssetsTotal;
    };
    var calculateUnbalancedLiabilities = function (currentLiabilities) {
        var values = [
            Number(currencyToNumber(currentLiabilities.overdraft)),
            Number(currencyToNumber(currentLiabilities.invoiceDiscounting)),
            Number(currencyToNumber(currentLiabilities.tradeCreditorsTotal)),
            Number(currencyToNumber(currentLiabilities.payeNi)),
            Number(currencyToNumber(currentLiabilities.vat)),
            Number(currencyToNumber(currentLiabilities.corporationTax)),
        ];
        var total = values.reduce(function (sum, value) { return sum + value; });
        currentLiabilities.creditors.forEach(function (creditor) {
            if (!creditor._destroy) {
                total += currencyToNumber(creditor.amount);
            }
        });
        currentLiabilities.existingLoans.forEach(function (existingLoan) {
            if (!existingLoan._destroy) {
                total += currencyToNumber(existingLoan.amountOutstanding);
            }
        });
        return total;
    };
    var calculateUnbalancedNetAssets = function (openingBalanceSheet) {
        return calculateUnbalancedAssets(openingBalanceSheet) - calculateUnbalancedLiabilities(openingBalanceSheet.currentLiabilities);
    };
    var onSave = function () {
        setSubmitting(true);
        OpeningBalanceSheetApi.updateOpeningBalanceSheet(stateToApi(data)).then(function (_a) {
            var json = _a[0], response = _a[1];
            if (response.ok) {
                window.location.href = json.url;
            }
            else {
                setError({
                    message: 'An error occurred attempting to save the Opening Balance Sheet:',
                    details: json.errors
                });
                window.scrollTo(0, 0);
            }
            setSubmitting(false);
        });
    };
    if (error && !isLoaded) {
        return React.createElement("div", { role: 'alert', className: 'fade alert alert-danger show' }, error.message);
    }
    if (!isLoaded) {
        return React.createElement("div", { className: "spinner-border", role: "status" },
            React.createElement("span", { className: "sr-only" }, "Loading..."));
    }
    else {
        return React.createElement(React.Fragment, null,
            error &&
                React.createElement("div", { role: 'alert', className: 'fade alert alert-danger show' },
                    error.message, (_a = error.details) === null || _a === void 0 ? void 0 :
                    _a.map(function (error, index) {
                        return React.createElement("li", { key: index }, error);
                    })),
            React.createElement(FixedAssetsCard, { fixedAssets: data.fixedAssets, setParent: updateDataField('fixedAssets') }),
            React.createElement(CurrentAssetsCard, { openingBalanceSheet: data, setParent: updateDataField('currentAssets') }),
            React.createElement(CurrentLiabilitiesCard, { openingBalanceSheet: data, setParent: updateDataField('currentLiabilities') }),
            React.createElement(AdditionalFieldsCard, { additionalFields: data.additionalFields, setParent: updateDataField('additionalFields') }),
            React.createElement("div", { className: 'card mb-4' },
                React.createElement("div", { className: 'card-body text-right' },
                    React.createElement("input", { type: 'submit', value: 'Save', className: 'btn btn-primary px-5', onClick: onSave, disabled: submitting }))));
    }
}
export default OpeningBalanceSheet;
