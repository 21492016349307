var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { uuid } from '../utils/uuid';
import { numberToCurrency, currencyToNumber } from '../../misc/currency';
export function CurrencyInput(_a) {
    var setHook = _a.setHook, _b = _a.id, id = _b === void 0 ? uuid() : _b, _c = _a.className, className = _c === void 0 ? '' : _c, otherProps = __rest(_a, ["setHook", "id", "className"]);
    return React.createElement("input", __assign({ id: id, className: "form-control string " + className, onFocus: function (e) {
            var value = e.target.value;
            value = value ? String(currencyToNumber(value).toFixed(2)) : '';
            setHook(value);
            setTimeout(function () { return e.target.select(); }, 0);
        }, onChange: function (e) { return setHook(e.target.value); }, onBlur: function (e) {
            setHook(numberToCurrency(e.target.value));
        } }, otherProps));
}
export function CurrencyInputWithLabel(_a) {
    var label = _a.label, value = _a.value, setHook = _a.setHook, _b = _a.disabled, disabled = _b === void 0 ? false : _b;
    var id = uuid();
    return (React.createElement("div", { className: 'form-group row string' },
        React.createElement("label", { className: 'col-2 col-form-label string', htmlFor: id }, label),
        React.createElement("div", { className: 'col-3' },
            React.createElement(CurrencyInput, { id: id, value: value, setHook: setHook, disabled: disabled }))));
}
