var format = function (cents) {
    var poundsAndPence = (parseInt(cents.toString()) / 100).toFixed(2);
    return poundsAndPence === "NaN" ? "0" : poundsAndPence;
};
var parse = function (poundsAndPence) {
    return parseFloat(poundsAndPence.toString()) * 100;
};
export default {
    format: format,
    parse: parse
};
