import * as React from 'react';
import Select from 'react-select';
function FixedAssetCategorySelect(props) {
    var _a = React.useState(null), error = _a[0], setError = _a[1];
    var _b = React.useState([]), options = _b[0], setOptions = _b[1];
    var _c = React.useState({}), value = _c[0], setValue = _c[1];
    React.useEffect(function () {
        fetch('/fixed_asset_categories.json')
            .then(function (res) { return res.json(); })
            .then(function (result) {
            var asOptions = result.map(function (category) {
                return { value: category.id, label: category.name };
            });
            setOptions(asOptions);
            // Need to search the options for the props.value id:
            // https://github.com/JedWatson/react-select/issues/3063
            var selectedOption = asOptions.filter(function (option) { var _a; return ((_a = props.value) === null || _a === void 0 ? void 0 : _a.id) === option.value; })[0];
            setValue(selectedOption);
        }, function (error) {
            setError(error);
        });
    }, []);
    var handleOnChange = function (option) {
        props.setHook({ id: option.value, name: option.label });
        setValue(option);
    };
    if (error) {
        return React.createElement(React.Fragment, null,
            React.createElement("div", null,
                "Error: ",
                error.message));
    }
    else {
        return React.createElement(React.Fragment, null,
            React.createElement(Select, { onChange: handleOnChange, options: options, value: value }));
    }
}
export default FixedAssetCategorySelect;
