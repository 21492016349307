import * as React from 'react';
import moment from 'moment';
import { TimePeriodTotals } from "../DataTable";
import { parseNumber, numberWithCommas } from "../../../misc/number_formatter";
export default function FooterRow(_a) {
    var cellHint = _a.cellHint, collection = _a.collection, monthsForYear = _a.monthsForYear, timePeriodTotals = _a.timePeriodTotals, yearTotalsEvaluator = _a.yearTotalsEvaluator, monthTotalsEvaluator = _a.monthTotalsEvaluator, hasOpeningBalance = _a.hasOpeningBalance;
    var cells = Object.entries(collection).flatMap(function (_a, index) {
        var parentId = _a[0], row = _a[1];
        return Object.entries(row.cells).flatMap(function (_a, index) {
            var cellId = _a[0], columnData = _a[1];
            return columnData;
        });
    });
    var calculateYearTotal = function (monthsForYear, year) {
        if (yearTotalsEvaluator) {
            return yearTotalsEvaluator(year);
        }
        else {
            return numberWithCommas(cells.filter(function (c) {
                return moment(c.month).isBetween(monthsForYear[0], monthsForYear[monthsForYear.length - 1], undefined, '[]');
            }).map(function (c) { return parseNumber(c.value.toString()); })
                .reduce(function (sum, current) { return sum + current; }, 0));
        }
    };
    var calculateOpeningTotal = function () {
        var total = Object.values(collection).reduce(function (sum, row) {
            if (row.opening_balance) {
                sum += parseNumber(row.opening_balance.toString());
            }
            return sum;
        }, 0);
        return numberWithCommas(total);
    };
    return (React.createElement("tr", null,
        React.createElement("th", null),
        monthsForYear.map(function (month, index) {
            var isNewYear = index !== 0 && index % 12 === 0;
            var isFirstMonth = index === 0;
            var monthFormat = month.format('MMM YYYY');
            var yearTotal = '';
            if (isNewYear) {
                yearTotal = calculateYearTotal(monthsForYear.slice(index - 12, index), index / 12);
            }
            var monthTotal = '';
            if (monthTotalsEvaluator) {
                monthTotal = monthTotalsEvaluator(month);
            }
            else {
                monthTotal = numberWithCommas(cells.filter(function (c) {
                    return moment(c.month).isSame(month);
                })
                    .map(function (c) { return parseNumber(c.value.toString()); })
                    .reduce(function (sum, current) { return sum + current; }, 0));
            }
            return (React.createElement(React.Fragment, { key: monthFormat },
                hasOpeningBalance && isFirstMonth && React.createElement("th", { className: 'cell' },
                    React.createElement("div", { className: 'cell-no-padding' },
                        React.createElement("div", { className: 'cell-width' }),
                        cellHint && React.createElement("span", { className: "cell-hint-text" }, cellHint),
                        React.createElement("input", { type: "text", readOnly: true, className: "no-highlight total-input", value: calculateOpeningTotal(), style: { width: "100%", textAlign: 'right' } }))),
                isNewYear && timePeriodTotals === TimePeriodTotals.Both && React.createElement("th", { className: 'yearDivider cell' },
                    React.createElement("div", { className: 'cell-no-padding' },
                        React.createElement("div", { className: 'cell-width' }),
                        cellHint && React.createElement("span", { className: "cell-hint-text" }, cellHint),
                        React.createElement("input", { type: "text", readOnly: true, className: "no-highlight total-input", value: yearTotal, style: { width: "100%", textAlign: 'right' } }))),
                React.createElement("th", { className: isNewYear ? 'yearDivider cell' : 'cell' },
                    React.createElement("div", { className: 'cell-no-padding' },
                        React.createElement("div", { className: 'cell-width' }),
                        cellHint && React.createElement("span", { className: "cell-hint-text" }, cellHint),
                        React.createElement("input", { type: "text", readOnly: true, className: "no-highlight total-input", value: monthTotal, style: { width: "100%", textAlign: 'right' } })))));
        }),
        timePeriodTotals === TimePeriodTotals.Both && React.createElement("th", { className: 'yearDivider cell' },
            React.createElement("div", { className: 'cell-no-padding' },
                React.createElement("div", { className: 'cell-width' }),
                cellHint && React.createElement("span", { className: "cell-hint-text" }, cellHint),
                React.createElement("input", { type: "text", readOnly: true, className: "no-highlight total-input", value: calculateYearTotal(monthsForYear.slice(monthsForYear.length - 12, monthsForYear.length), monthsForYear.length / 12), style: { width: "100%", textAlign: 'right' } })))));
}
