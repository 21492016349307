var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import * as React from 'react';
import FixedAssetRow from './FixedAssetRow';
import { emptyFixedAsset } from '../../modules/opening-balance-sheet/types';
function FixedAssetsCard(_a) {
    var fixedAssets = _a.fixedAssets, setParent = _a.setParent;
    var _b = React.useState(fixedAssets), propFixedAssets = _b[0], setFixedAssets = _b[1];
    var addFixedAsset = function () {
        var newFixedAssets = propFixedAssets.concat(emptyFixedAsset());
        setFixedAssets(newFixedAssets);
        setParent(newFixedAssets);
    };
    var removeFixedAsset = function (id) { return function () {
        updateFixedAssets(id)('_destroy', true);
    }; };
    var updateFixedAssets = function (id) { return function (field, value) {
        var clone = __spreadArrays(propFixedAssets);
        for (var i in clone) {
            if (clone[i].id == id) {
                clone[i][field] = value;
                break;
            }
        }
        setParent(clone);
    }; };
    return (React.createElement("div", { className: 'card mb-4' },
        React.createElement("div", { className: 'card-header' }, "Fixed Assets"),
        React.createElement("div", { className: 'card-body' },
            React.createElement("div", { className: 'card-body bg-light rounded border mb-4' },
                React.createElement("a", { href: '/fixed_asset_categories' }, "Manage Categories")),
            React.createElement("table", { className: 'table padded no-top-border' },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", { className: 'w35p' }, "Category"),
                        React.createElement("th", { className: 'w30p' }, "Original Value"),
                        React.createElement("th", { className: 'w30p' }, "Book Value"),
                        React.createElement("th", { className: 'w5p' }))),
                React.createElement("tbody", null, propFixedAssets.filter(function (fixedAsset) { return !fixedAsset._destroy; }).map(function (fixedAsset, index) {
                    return React.createElement(FixedAssetRow, { key: fixedAsset.id, fixedAsset: fixedAsset, handleRemoveRow: removeFixedAsset(fixedAsset.id), setParent: updateFixedAssets(fixedAsset.id) });
                }))),
            React.createElement("div", { className: 'text-right' },
                React.createElement("a", { className: 'btn btn-light border', onClick: addFixedAsset },
                    React.createElement("i", { className: 'fas fa-plus mr-2' }),
                    "Add Fixed Asset")))));
}
export default FixedAssetsCard;
