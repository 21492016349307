var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { CurrencyInputWithLabel, CurrencyInput } from '../CurrencyInput';
import { isZero, currencyToNumber } from '../../../misc/currency';
import DebtorsTable from './DebtorsTable';
function CurrentAssetsCard(props) {
    var _a = React.useState(props.openingBalanceSheet.currentAssets), currentAssets = _a[0], setCurrentAssets = _a[1];
    var _b = React.useState({}), error = _b[0], setError = _b[1];
    var validateTradeDebtorsTotal = function (assets) {
        var total = [
            currencyToNumber(assets.tradeDebtorsMonth1),
            currencyToNumber(assets.tradeDebtorsMonth2),
            currencyToNumber(assets.tradeDebtorsMonth3),
            currencyToNumber(assets.tradeDebtorsMonth4),
        ].reduce(function (sum, month) { return sum + month; });
        if (total !== currencyToNumber(assets.tradeDebtorsTotal)) {
            setError(__assign(__assign({}, error), { tradeDebtorsTotal: 'Received month splits must equal the total amount.' }));
        }
        else {
            setError(__assign(__assign({}, error), { tradeDebtorsTotal: undefined }));
        }
    };
    var updateField = function (field) { return function (value) {
        var clone = __assign({}, currentAssets);
        clone[field] = value;
        setCurrentAssets(clone);
        props.setParent(clone);
        validateTradeDebtorsTotal(clone);
    }; };
    return (React.createElement("div", { className: 'card mb-4' },
        React.createElement("div", { className: 'card-header' }, "Current Assets"),
        React.createElement("div", { className: 'card-body' },
            React.createElement(CurrencyInputWithLabel, { label: 'Stock', value: currentAssets.stock, setHook: updateField('stock') }),
            React.createElement("div", { className: 'form-row form-group' },
                React.createElement("div", { className: 'col-2 col-form-label', style: { marginTop: '30px' } },
                    React.createElement("label", null, "Trade Debtors")),
                React.createElement("div", { className: 'col-2' },
                    React.createElement("label", null,
                        React.createElement("strong", null, "Total")),
                    React.createElement(CurrencyInput, { value: currentAssets.tradeDebtorsTotal, setHook: updateField('tradeDebtorsTotal') })),
                React.createElement("div", { className: 'col-2' },
                    React.createElement("label", null,
                        React.createElement("strong", null, "Received month 1")),
                    React.createElement(CurrencyInput, { value: currentAssets.tradeDebtorsMonth1, setHook: updateField('tradeDebtorsMonth1'), className: error.tradeDebtorsTotal && 'is-invalid' })),
                React.createElement("div", { className: 'col-2' },
                    React.createElement("label", null,
                        React.createElement("strong", null, "Received month 2")),
                    React.createElement(CurrencyInput, { value: currentAssets.tradeDebtorsMonth2, setHook: updateField('tradeDebtorsMonth2'), className: error.tradeDebtorsTotal && 'is-invalid' })),
                React.createElement("div", { className: 'col-2' },
                    React.createElement("label", null,
                        React.createElement("strong", null, "Received month 3")),
                    React.createElement(CurrencyInput, { value: currentAssets.tradeDebtorsMonth3, setHook: updateField('tradeDebtorsMonth3'), className: error.tradeDebtorsTotal && 'is-invalid' })),
                React.createElement("div", { className: 'col-2' },
                    React.createElement("label", null,
                        React.createElement("strong", null, "Received month 4")),
                    React.createElement(CurrencyInput, { value: currentAssets.tradeDebtorsMonth4, setHook: updateField('tradeDebtorsMonth4'), className: error.tradeDebtorsTotal && 'is-invalid' })),
                error.tradeDebtorsTotal && (React.createElement("div", { className: 'col offset-4' },
                    React.createElement("div", { className: "small text-danger" }, error.tradeDebtorsTotal)))),
            React.createElement(CurrencyInputWithLabel, { label: 'Cash in Bank', value: currentAssets.cashInBank, setHook: updateField('cashInBank'), disabled: !isZero(props.openingBalanceSheet.currentLiabilities.overdraft) }),
            React.createElement("div", { className: 'row' },
                React.createElement("div", { className: 'col-2 col-form-label' }, "Other Debtors"),
                React.createElement("div", { className: 'col-9' },
                    React.createElement(DebtorsTable, { debtors: currentAssets.debtors, setParent: updateField('debtors') }))),
            React.createElement(CurrencyInputWithLabel, { label: 'Auto-Balance Correction', value: currentAssets.autoBalanceCorrection, setHook: updateField('autoBalanceCorrection'), disabled: true }),
            React.createElement("strong", null,
                React.createElement(CurrencyInputWithLabel, { label: 'Total', value: currentAssets.total, setHook: updateField('total'), disabled: true })))));
}
export default CurrentAssetsCard;
